<template>
  <div class="text-center">
    <v-dialog v-model="showModel" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Update Upcoming Event</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form
              ref="upcomingEventForm"
              v-model="valid"
              lazy-validation
            >
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    :rules="[v => !!v || 'Events is required']"
                    label="Events *"
                    item-text="name"
                    item-value="_id"
                    v-model="editUpcomingEventForm.event_id"
                    :items="allEvent"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editUpcomingEventForm.date"
                        label="Upcoming Event Date *"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="[v => !!v || 'Upcoming Event Date is required']"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="editUpcomingEventForm.date"
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="resetUpdateUpcomingEventModal()">
            Close
          </v-btn>
          <v-btn color="primary" @click="updateUpcomingEvent()"> Update </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <snackbar
      :text="snackbarText"
      :showSnackbar="isShowSnackbar"
      @hide="isShowSnackbar = false"
    ></snackbar>
  </div>
</template>
<script>
import axios from "axios";
import Snackbar from "../../Snackbar.vue";
export default {
  name: "UpdateUpcomingEvent",
  components: {
    Snackbar,
  },
  props: {
    showModel: {
      type: Boolean,
      default: false,
    },
    upcomingEventDetail: {
      type: Object,
    },
  },
  data: () => ({
    editUpcomingEventForm:{
      event_id:'',
      date: '',
    },
    allEvent:[],
    valid: true,
    menu2: false,
    showUpdateUpcomingEventModal:false
  }),
  watch: {
    showModel(val) {
      this.showUpdateUpcomingEventModal = val;
      this.getAllEventList();
    },
    upcomingEventDetail(val){
        this.editUpcomingEventForm.event_id = val.event_id._id;
        this.editUpcomingEventForm.date =  new Date(val.date).toISOString().slice(0,10);
    }
  },
  computed: {},
  mounted: function () {
  },
  created: function () {},
  methods: {
    resetUpdateUpcomingEventModal() {
      this.$refs.upcomingEventForm.reset();
      this.$emit('close');
    },
    updateUpcomingEvent() {
      if (this.$refs.upcomingEventForm.validate()) {
        this.editUpcomingEventForm._id = this.upcomingEventDetail._id;
        axios.put(this.Node_JS_HOST + "/api/v1/web/banner-master/upcoming-event",this.editUpcomingEventForm)
        .then((res) => {
            if (res) {
              this.resetUpdateUpcomingEventModal();
              this.snackbarText = "Upcoming Event add Successfully";
              this.isShowSnackbar = true;
            }
          })
          .catch((error) => {
            this.errorHandle(error);
            this.snackbarText = "Please insert valid input";
            this.isShowSnackbar = true;
          });
      }
    },
    getAllEventList(){
      axios.get(this.Node_JS_HOST + "/api/v1/web/banner-master/event")
      .then((res) => {
          const response = res.data;
          this.allEvent = response.data.allEvent;
      })
    }
  },
};
</script>