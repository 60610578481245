<template>
  <v-container fluid class="down-top-padding">
    <v-row>
      <v-col cols="12" sm="12">
        <v-card class="mb-7">
          <v-toolbar flat>
            <v-toolbar-title>Upcoming Event List</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              @click="addUpcomingEventModal = true"
              class="mx-2"
              fab
              dark
              small
              color="#1976d2"
            >
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider></v-divider>
          <div class="pa-4">
            <v-data-table
              class="mx-5"
              :loading="tableLoading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :search="search"
              :items="allUpcomingEvent"
              :footer-props="{
                'items-per-page-options': [50, 100, 150, 200, 300, -1]
              }"
              :items-per-page="50"
              :custom-filter="filterOnlyCapsText"
            >
              <template v-slot:top>
                <v-text-field
                  v-model="search"
                  label="Search (Upcoming Event Details)"
                  class="mx-4"
                ></v-text-field>
              </template>
              <template v-slot:item="row">
                <tr>
                  <td>{{ row.item.event_id.name }}</td>
                  <td>{{ dateFormat(row.item.date) }}</td>
                  <td>{{ dateFormat(row.item.created_date) }}</td>
                  <td><a @click="UpdateUpcomingEventModel(row.item)"><v-icon color="primary">mdi-pencil</v-icon></a> | <a @click="showDeleteDilog(row.item)"><v-icon color="red">mdi-delete</v-icon></a></td>
                </tr>
              </template>
              <template v-slot:no-results>NO RESULTS HERE</template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <snackbar :text="snackbarText" :showSnackbar="isShowSnackbar" @hide="isShowSnackbar = false"></snackbar>
    <create-upcoming-event :show-model="addUpcomingEventModal" @close="closeUpcomingEventModal"></create-upcoming-event>
    <update-upcoming-event :show-model="updateUpcomingEventModal" @close="closeUpcomingEventModal" :upcoming-event-detail="updateUpComingEventDetail"></update-upcoming-event>
    <delete-dialog :contentText="deleteText" :showModel="showDeleteModel" :id="deleteId" :headerText="'Delete Upcoming Event'" @action="deleteAction"></delete-dialog>
  </v-container>
</template>
<script>
import axios from "axios";
import Snackbar from '../../Snackbar.vue';
import CreateUpcomingEvent from './CreateUpcomingEvent.vue';
import UpdateUpcomingEvent from './UpdateUpcomingEvent.vue';
import DeleteDialog from '../../DeleteDialog.vue';
export default {
  name: "UpcomingEvent",
  components: {
    Snackbar,
    CreateUpcomingEvent,
    DeleteDialog,
    UpdateUpcomingEvent
  },
  data: () => ({
    search: "",
    addUpcomingEventModal: false,
    allUpcomingEvent:[],
    tableLoading:false,
    showDeleteModel:false,
    deleteId:'',
    deleteText:'',
    headers: [
      { text: "Name", value: "event_id.name" },
      { text: "Date", value: "date" },
      { text: "Created at", value: "created_date" },
      { text: "Action" },
    ],
    updateUpcomingEventModal:false,
    updateUpComingEventDetail:{},
  }),
  computed: {},
  mounted: function () { 
    this.getUpcomingEventModal();
  },
  created: function () {},
  methods: {
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
      );
    },
    closeUpcomingEventModal(){
      this.getUpcomingEventModal();
      this.addUpcomingEventModal = false;
      this.updateUpcomingEventModal = false;
    },

    getUpcomingEventModal(){
      this.tableLoading = true;
      axios.get(this.Node_JS_HOST + "/api/v1/web/banner-master/upcoming-event")
        .then((res) => {
          const response = res.data;
          this.allUpcomingEvent = response.data;
          this.tableLoading = false;
        })
        .catch((error) => {
          this.errorHandle(error);
          this.tableLoading = false;
        });
    },
    UpdateUpcomingEventModel(eventDetail){
      this.updateUpcomingEventModal = true;
      this.updateUpComingEventDetail = eventDetail;
    },
    showDeleteDilog(upcomingEventDetail){
      this.deleteText = 'Are you sure, You want to delete this <b>'+ upcomingEventDetail.event_id.name +'</b> Upcoming Event.';
      this.deleteId = upcomingEventDetail._id;
      this.showDeleteModel = true;
    },
    deleteAction(actionType,id){
      if(actionType == 'delete'){
        axios.delete(this.Node_JS_HOST + "/api/v1/web/banner-master/upcoming-event/"+id)
        .then((res) => {
          if(res){
            this.getUpcomingEventModal();
            this.snackbarText = 'Upcoming Event Deleted';
            this.isShowSnackbar = true;
          }
        })
        .catch((error) => {
          this.errorHandle(error);
          this.snackbarText = 'Something went wrong please try again';
          this.isShowSnackbar = true;
        });
      }
      this.showDeleteModel = false;
      this.deleteId = '';
    },
  },
};
</script>
<style scoped>
  ::v-deep th {
    white-space: nowrap!important;
  }
  .w-100{
    max-width: 100px;
  }
  .w-unset{
    max-width: unset;
  }
</style>